<div class="container">
    <div class="profile">

        <div class="profile-settings-section">
            <div class="heading">
                Setting up your account
            </div>
            <div class="form-section">
                <form class="form">
                    <mat-card>
                        <mat-card-content>
                            <mat-card-title>Personal Info</mat-card-title>
                            <div class="profile-pic-section" style="float: left;">
                                <img class="profile-img" src="assets/images/traveler3.jpg" alt="">
                            </div>

                            <div class="form-group" style="width:70%;">
                                <mat-form-field>
                                    <mat-label>First name</mat-label>
                                    <input matInput placeholder="Enter your first name">
                                    <mat-hint>Hint</mat-hint>
                                </mat-form-field>
                            </div>

                            <div class="form-group" style="width:70%;">
                                <mat-form-field>
                                    <mat-label>Last name</mat-label>
                                    <input matInput placeholder="Enter your last name">
                                    <mat-hint>Hint</mat-hint>
                                </mat-form-field>
                            </div>

                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>Email</mat-label>
                                    <input matInput placeholder="Enter your email" value="johbdoe@email.com" disabled>
                                </mat-form-field>
                            </div>

                            <div class="form-group" style="width:70%;">
                                <ngx-material-intl-tel-input [required]="true" [autoIpLookup]="false">
                                </ngx-material-intl-tel-input>
                            </div>
                            <div class="form-group" style="width:70%; margin-left: 30%;">
                                <div class="form-radio">
                                    <mat-label>
                                        Gender: 
                                    </mat-label>
                                    <mat-radio-group aria-label="gender">
                                        <mat-radio-button value="1">Male</mat-radio-button>
                                        <mat-radio-button value="2">Female</mat-radio-button>
                                        <mat-radio-button value="3">Others</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>User type</mat-label>
                                    <mat-select [(value)]="selected">
                                        <mat-option value="one">Marveler</mat-option>
                                        <mat-option value="two" selected disabled>Counselor</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Birthdate</mat-label>
                                    <input matInput [matDatepicker]="picker">
                                    <mat-hint>MM/DD/YYYY</mat-hint>
                                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="form-group">
                                <a class="form-link">
                                    change password
                                </a>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card>
                        <mat-card-content>
                            <mat-card-title>Education & Certificates</mat-card-title>
                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>Certifications</mat-label>
                                    <mat-chip-grid #chipGrid aria-label="Enter Certificate">
                                        <input class="form-input" placeholder="Add Certificate"
                                            [matChipInputFor]="chipGrid"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" />
                                        @for (tag of tags; track tag) {
                                        <mat-chip-row (removed)="remove(tag)" [editable]="true"
                                            (edited)="edit(tag, $event)"
                                            [aria-description]="'press enter to edit ' + tag.name">
                                            {{tag.name}}
                                            <button matChipRemove [attr.aria-label]="'remove ' + tag.name">
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip-row>
                                        }

                                    </mat-chip-grid>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>
                                        Education Level
                                    </mat-label>
                                    <mat-select class="form-input dropdown" id="education" name="education">
                                        <mat-option value="" selected="selected" disabled="disabled">-- select one
                                            --</mat-option>
                                        <mat-option value="No formal education">No formal education</mat-option>
                                        <mat-option value="Primary education">Primary education</mat-option>
                                        <mat-option value="Secondary education">Secondary education or high
                                            school</mat-option>
                                        <mat-option value="GED">GED</mat-option>
                                        <mat-option value="Vocational qualification">Vocational
                                            qualification</mat-option>
                                        <mat-option value="Bachelor's degree">Bachelor's degree</mat-option>
                                        <mat-option value="Master's degree">Master's degree</mat-option>
                                        <mat-option value="Doctorate or higher">Doctorate or higher</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card>
                        <mat-card-content>
                            <mat-card-title>Career & work history</mat-card-title>
                            <div class="form-group">
                                <mat-form-field class="example-full-width">
                                    <textarea matInput placeholder="Career & work"></textarea>
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card>
                        <mat-card-content>
                            <mat-card-title>General Info</mat-card-title>
                            <div class="form-group">
                                <div class="form-radio">
                                    <mat-label>
                                        Do you have a vehicle?
                                    </mat-label>
                                    <mat-radio-group aria-label="Do you have a vehicle? ">
                                        <mat-radio-button value="1">Yes</mat-radio-button>
                                        <mat-radio-button value="2">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>
                                        What is you car type?
                                    </mat-label>
                                    <mat-select id="vehicle_type" name="vehicle_type">
                                        <mat-option value="" selected="selected" disabled="disabled">-- select one
                                            --</mat-option>
                                        <mat-option value="Car">Car</mat-option>
                                        <mat-option value="Truck">Truck</mat-option>
                                        <mat-option value="SUV">SUV (Sport Utility Vehicle)</mat-option>
                                        <mat-option value="Van">Van</mat-option>
                                        <mat-option value="Motorcycle">Motorcycle</mat-option>
                                        <mat-option value="Bus">Bus</mat-option>
                                        <mat-option value="RV">RV (Recreational Vehicle)</mat-option>
                                        <mat-option value="ATV">ATV (All-Terrain Vehicle)</mat-option>
                                        <mat-option value="Boat">Boat</mat-option>
                                        <mat-option value="Aircraft">Aircraft</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>The city where you will offer the service</mat-label>
                                    <input matInput placeholder="Enter your city">
                                    <mat-hint>Hint</mat-hint>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>How long did you live there?</mat-label>
                                    <input matInput placeholder="Enter number of years">
                                    <mat-hint>Hint</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="form-group">
                                <mat-form-field class="example-full-width">
                                    <mat-label>About you</mat-label>
                                    <textarea matInput placeholder="describe your self"></textarea>
                                </mat-form-field>
                            </div>

                        </mat-card-content>
                    </mat-card>
                    <mat-card>
                        <mat-card-content>
                            <mat-card-title>About your health</mat-card-title>
                            <div class="form-group">
                                <div class="form-radio">
                                    <mat-label>
                                        Do you have any special needs?
                                    </mat-label>
                                    <mat-radio-group>
                                        <mat-radio-button value="1">Yes</mat-radio-button>
                                        <mat-radio-button value="2">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="form-radio">
                                    <mat-label>
                                        Are you a smoker?
                                    </mat-label>
                                    <mat-radio-group>
                                        <mat-radio-button value="1">Yes</mat-radio-button>
                                        <mat-radio-button value="2">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card>
                        <mat-card-content>
                            <mat-card-title>Social media</mat-card-title>
                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>Facebook</mat-label>
                                    <input matInput placeholder="Facebook">
                                    <mat-hint>Hint</mat-hint>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>X</mat-label>
                                    <input matInput placeholder="X">
                                    <mat-hint>Hint</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>LinkedIn</mat-label>
                                    <input matInput placeholder="LinkedIn">
                                    <mat-hint>Hint</mat-hint>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Tiktok</mat-label>
                                    <input matInput placeholder="Tiktok">
                                    <mat-hint>Hint</mat-hint>
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card>
                        <mat-card-content>
                            <mat-card-title>Language</mat-card-title>
                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>Spoken language</mat-label>
                                    <input matInput placeholder="Enter your native language">
                                    <mat-hint>Hint</mat-hint>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Languages you know</mat-label>
                                    <input matInput placeholder="Enter languages you know">
                                    <mat-hint>Hint</mat-hint>
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card>
                        <mat-card-content>
                            <mat-card-title>Keywords & tags</mat-card-title>
                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>Certifications</mat-label>
                                    <mat-chip-grid #tagsChipGrid aria-label="Enter Tags">
                                        <input class="form-input" placeholder="Add Tags"
                                            [matChipInputFor]="tagsChipGrid"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" />
                                        @for (tag of tags; track tag) {
                                        <mat-chip-row (removed)="remove(tag)" [editable]="true"
                                            (edited)="edit(tag, $event)"
                                            [aria-description]="'press enter to edit ' + tag.name">
                                            {{tag.name}}
                                            <button matChipRemove [attr.aria-label]="'remove ' + tag.name">
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip-row>
                                        }

                                    </mat-chip-grid>
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card>
                        <mat-card-content>
                            <mat-card-title>Nationality</mat-card-title>
                            <div class="form-group">
                                <mat-select [(value)]="nationalitySelected">
                                    <mat-option value="usa">United States</mat-option>
                                    <mat-option value="canada">Canada</mat-option>
                                    <mat-option value="uk">United Kingdom</mat-option>
                                    <mat-option value="germany">Germany</mat-option>
                                    <mat-option value="france">France</mat-option>
                                    <mat-option value="china">China</mat-option>
                                    <mat-option value="japan">Japan</mat-option>
                                    <mat-option value="india">India</mat-option>
                                    <mat-option value="brazil">Brazil</mat-option>
                                    <mat-option value="australia">Australia</mat-option>
                                    <mat-option value="russia">Russia</mat-option>
                                    <mat-option value="italy">Italy</mat-option>
                                    <mat-option value="spain">Spain</mat-option>
                                    <mat-option value="south-korea">South Korea</mat-option>
                                    <mat-option value="mexico">Mexico</mat-option>
                                </mat-select>
                            </div>
                            <div class="form-group">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Services</mat-label>
                                    <textarea matInput placeholder="Add your services"></textarea>
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <button class="primary-btn primary-btn--primary">
                        save & submit
                    </button>
                </form>
            </div>
        </div>
    </div>

</div>